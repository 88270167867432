header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__container h5 {
  font-size: 1rem;
}
/* CTA */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* HEADER SOCIALS */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials a {
  font-size: 1.2rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* WALID */
.walid {
  width: 22rem;
  height: 22.8rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.2rem;
}

/* MEDIA QUERIES MD */
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    padding-top: 4rem;
  }

  .walid {
    width: 18rem;
    height: 18.8rem;
    left: calc(50% - 9rem);
    margin-top: 8rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
